<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
 .sys-table-input{
   height: 25px;
   margin-top: 0;
 }
</style>
<script>
  import Layout from "@/views/layouts/main";
  // import ParamsHeader from "./params-header.vue";
  import {
    list,
    typeList,
    updateConfig,
    freshConfig
  } from "@/api/system/sysConfig";

  /**
   * 系统管理--参数配置
   */
  export default {
    components: {
      Layout,
      // ParamsHeader,



    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统管理",
            active: true
          }
        ],
        query:{
          keyword: "",
          pid:"",
          pageNum: 1,
          pageSize: 20
        },
        sysConfigList: [],
        typeList: [],
        pid: "",
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0
      };
    },
    created() {
      this.getTypeList();
      this.getList();

    },
    computed: {
      rows() {
        return this.sysConfigList.length;
      },
    },
    methods: {
      /** 查询系统参数列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        list(this.query).then(res => {
          if (res.status) {
            this.sysConfigList = res.data
            this.total = res.total;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      /** 查询系统参数列表 */
      getTypeList() {
        typeList().then(res => {
          if (res.status) {
            this.typeList = res.data
          }
        });
      },
      /** 失去焦点修改input中的值 */
      blurChange(row, event) {
        row.paramValue = event.target.value
      },
      /** 修改参数值 */
      handleUpdate(row) {
        this.$confirm('是否确认修改参数"' + row.paramTitle + '"的值为:'+row.paramValue+'?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return updateConfig(row);
        }).then(() => {
          this.getList();
          this.$message({
            type: 'success',
            message: "修改成功"
          });
        })
      },
      /** 刷新配置参数 */
      refreshConifg() {
        this.$confirm('是否确认刷新配置参数?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return freshConfig();
        }).then(() => {
          this.$message({
            type: 'success',
            message: "刷新成功"
          });
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <!--<ParamsHeader :items="items" />-->

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3  border-dash check-table-top">
              <div class="flexList">
                <el-select class=" mr-2 blueBorder" style="width: 30%;" placeholder="请选择参数类型" v-model="query.pid"
                           size="small">
                  <el-option value="" label="所有类型参数"></el-option>
                  <el-option :value="item.pid" v-for="item in typeList" :key="item.pid" :label="item.paramSortTitle">{{item.paramSortTitle}}
                  </el-option>
                </el-select>
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
                <b-button variant="light" class="flexList light-bg mr-2 " @click="refreshConifg()" >参数配置刷新</b-button>
                <b-button variant="light" class="flexList light-bg mr-2 ">加密工具</b-button>
              </div>
              <!-- <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div> -->
            </div>

            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th style="width:12%">参数</th>
                    <th style="width:20%">参数名称</th>
                    <th style="width:20%">参数值 </th>
                    <th>说明 </th>
                    <th style="width:12%">最后更新 </th>
                    <th style="width: 5%;">管理 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in sysConfigList" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.paramName}}</td>
                    <td>{{obj.paramTitle}}</td>
                    <td><input type="" :id="obj.sid" :value="obj.paramValue" @blur="blurChange(obj, $event)"  class="form-control sys-table-input"/> </td>
                    <td><div class="line3">{{obj.paramMemo}}</div></td>
                    <td>{{obj.modifyTime}}</td>
                    <td><b-button variant="info" class="h30 mr-2 m-55 " @click="handleUpdate(obj)">修改</b-button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="currentPage" :page-size="perPage"
                             layout="total, sizes,jumper, prev, pager, next" :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
