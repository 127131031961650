import { getRequest, patchRequest } from "@/libs/axios";

// -----------------------配置参数------------------------
// 查询参数列表
export function list(params) {
    return getRequest("/yethan/sysConfig/listPage", params);
}
// 查询参数类型列表
export function typeList(params) {
    return getRequest("/yethan/sysConfig/typeList", params);
}
// 修改参数
export function updateConfig(params) {
    return patchRequest("/yethan/sysConfig/update", params);
}
// 刷新配置参数
export function freshConfig() {
    return getRequest("/yethan/sysConfig/refresh");
}



